import { useState } from 'react';

import { Booking, BookingPermission, canManageBooking } from '@ivaoaero/atc';

import { ActionIcon, Badge, Card, Col, Grid, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconMicrophoneOff, IconPencil, IconTrashX } from '@tabler/icons';
import dayjs from 'dayjs';

import { userNickname } from '../../../../utils/UserNickname';
import { useUser } from '../../../auth';
import { useTimeline } from '../../../auth/hooks/useCheckTimeline';
import { usePermissions } from '../../../auth/hooks/usePermissions';
import { ShownDate } from '../../types/shownDate';
import BookingModal from '../BookingInfoModal';
import DeleteModal from '../DeleteModal';
import { Timeline } from '../Timeline';
import photo from './airplane.jpg';
import styles from './index.module.scss';

interface DayProps {
  bookings: Booking[];
  date: ShownDate;
  edit(booking_to_edit: Booking): void;
  filter?: string;
  readOnly?: boolean;
}

export const Day = (props: DayProps) => {
  const { user } = useUser();
  const timeline = useTimeline();
  const { data: userPermissions } = usePermissions();
  const [
    deleteModalOpened,
    { open: openDeleteModal, close: closeDeleteModal },
  ] = useDisclosure(false);
  const [
    bookingModalOpened,
    { open: openBookingModal, close: closeBookingModal },
  ] = useDisclosure(false);
  const [deleteBooking, setDeleteBooking] = useState<Booking | undefined>();
  const [modalUserBooking, setModalUserBooking] = useState<
    Booking | undefined
  >();

  const handleDelete = (booking: Booking) => {
    setDeleteBooking(booking);
    openDeleteModal();
  };

  const handleUserBooking = (booking: Booking) => {
    setModalUserBooking(booking);
    openBookingModal();
  };

  if (props.bookings.length === 0) {
    return (
      <Grid>
        <Col>
          <Card shadow="lg" p="lg" radius="md" withBorder>
            <div className={styles.booking}>
              <Card.Section className={styles.imageSection}>
                <img
                  style={{ borderRadius: '10px' }}
                  src={photo}
                  alt="Airport"
                />
                <p className={styles.credits}>© Pascal Meier</p>
              </Card.Section>
              <Group position="apart" align="center" className={styles.text}>
                <p>
                  {props.filter
                    ? 'No bookings for this filter'
                    : 'No bookings yet'}
                </p>
              </Group>
            </div>
          </Card>
        </Col>
      </Grid>
    );
  }

  if (timeline) {
    return (
      <>
        <DeleteModal
          opened={deleteModalOpened}
          close={closeDeleteModal}
          booking={deleteBooking}
        />
        <Timeline
          bookings={props.bookings}
          date={props.date}
          editBooking={(b) => props.edit(b)}
          handleDelete={handleDelete}
          user={user}
        />
      </>
    );
  } else {
    return (
      <>
        <DeleteModal
          opened={deleteModalOpened}
          close={closeDeleteModal}
          booking={deleteBooking}
        />
        <BookingModal
          opened={bookingModalOpened}
          close={closeBookingModal}
          booking={modalUserBooking}
        />
        <Grid columns={1}>
          {props.bookings
            .sort((a: Booking, b: Booking) => {
              const tempDate0 = new Date();
              tempDate0.setHours(
                a.startDate.getHours(),
                a.startDate.getMinutes(),
              );

              const tempDate1 = new Date();
              tempDate1.setHours(
                b.startDate.getHours(),
                b.startDate.getMinutes(),
              );

              const diff = dayjs(tempDate0).diff(tempDate1, 'minutes');

              if (diff < 0) {
                return -1;
              } else if (diff > 0) {
                return 1;
              } else {
                return 0;
              }
            })
            .map((booking: Booking) => {
              const canManage = canManageBooking(
                Object.keys(userPermissions ?? {}),
                user.id,
                booking,
              );

              return (
                <Col
                  key={props.bookings.indexOf(booking)}
                  className={styles.booking}
                >
                  <Card shadow="lg" p="lg" radius="md" withBorder>
                    <Group position="apart" align="center">
                      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
                      <p
                        onClick={() => handleUserBooking(booking)}
                        style={{
                          fontWeight: 600,
                          marginBottom: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {booking.atcPosition} (
                        {booking.atcPositionRef?.frequency
                          .toFixed(3)
                          .toString() ??
                          booking.subcenterRef?.frequency.toFixed(3).toString()}
                        ){' '}
                        {!booking.voice ? (
                          <IconMicrophoneOff size={20} />
                        ) : (
                          <></>
                        )}{' '}
                        {}
                      </p>
                      <Group align="center">
                        {booking.training ? (
                          <Badge
                            variant="light"
                            styles={{
                              inner: {
                                color: 'white',
                                fontFamily: 'Poppins',
                                fontWeight: 'normal',
                              },
                              root: {
                                backgroundColor: '#0D2C99',
                                margin: '5px 0px',
                              },
                            }}
                          >
                            {booking.training}
                          </Badge>
                        ) : (
                          <></>
                        )}
                        {!props.readOnly ? (
                          <div style={{ display: 'flex' }}>
                            {canManage.includes(BookingPermission.EDIT) ? (
                              <ActionIcon
                                variant="subtle"
                                size={20}
                                style={{
                                  padding: '1px',
                                  backgroundColor: '#0D2C99',
                                  margin: '5px',
                                }}
                                onClick={() => props.edit(booking)}
                              >
                                <IconPencil color="white"></IconPencil>
                              </ActionIcon>
                            ) : (
                              <></>
                            )}
                            {canManage.includes(BookingPermission.DELETE) ? (
                              <ActionIcon
                                variant="subtle"
                                size={20}
                                style={{
                                  padding: '1px',
                                  backgroundColor: 'red',
                                  margin: '5px',
                                }}
                                onClick={() => handleDelete(booking)}
                              >
                                <IconTrashX color="white"></IconTrashX>
                              </ActionIcon>
                            ) : (
                              <></>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </Group>
                    </Group>
                    <Group position="apart" align="center">
                      <p style={{ margin: 0, fontWeight: 400 }}>
                        {booking.user.firstName
                          ? `${userNickname(booking.user)}`
                          : booking.user.id}
                      </p>
                      <p style={{ margin: 0, fontWeight: 400 }}>
                        {booking.startDate
                          .getHours()
                          .toString()
                          .padStart(2, '0') +
                          ':' +
                          booking.startDate
                            .getMinutes()
                            .toString()
                            .padStart(2, '0') +
                          'z - ' +
                          booking.endDate
                            .getHours()
                            .toString()
                            .padStart(2, '0') +
                          ':' +
                          booking.endDate
                            .getMinutes()
                            .toString()
                            .padStart(2, '0') +
                          'z'}
                      </p>
                    </Group>
                  </Card>
                </Col>
              );
            })}
        </Grid>
      </>
    );
  }
};
