import { useEffect, useState } from 'react';

import {
  Autocomplete,
  Button,
  Flex,
  Input,
  Stack,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import { useDebouncedState } from '@mantine/hooks';
import { IconCalendar, IconClock, IconMapPin, IconUser } from '@tabler/icons';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import {
  checkUserFras,
  fetchAutocompleteData,
} from '../../../../utils/ApiRequest';
import { useUser } from '../../../auth';
import { ApiErrorResponse } from '../../types/api';
import { showError } from '../Notifications';

export const FraChecker = () => {
  const { user } = useUser();
  const theme = useMantineTheme();

  const [autoCompleteData, setAutoCompleteData] = useState<string[]>([]);
  const [vid, setVid] = useState<number>(user.id);
  const [position, setPosition] = useDebouncedState<string>('', 200);
  const [date, setDate] = useState<Date>(new Date());
  const [time, setTime] = useState<Date>(new Date());
  const [checkResponse, setCheckResponse] = useState<boolean | undefined>();
  const [checkResponseMessage, setCheckResponseMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function concatTime(date: Date, time: Date) {
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(0);

    // Set displayed time to UTC
    return new Date(
      dayjs(date).subtract(date.getTimezoneOffset(), 'minute').toDate(),
    );
  }

  useEffect(() => {
    if (position.length < 3) return;
    void fetchAutocompleteData(position).then((i) => {
      i.forEach((val, ind) => {
        if (val.includes('ATIS')) {
          i.splice(ind, 1); // remove ATIS from autocomplete data
        }
      });

      setAutoCompleteData(i);
    });
  }, [position]);

  const submitData = async () => {
    if (vid && position && date && time) {
      const temp_date = concatTime(date, time);

      try {
        setIsLoading(true);
        const fraCheckDescription = await checkUserFras(
          vid,
          position,
          temp_date.toISOString(),
        );
        setCheckResponseMessage(fraCheckDescription);
        setCheckResponse(true);
      } catch (err: unknown) {
        if (err instanceof AxiosError)
          setCheckResponseMessage(
            (err as AxiosError<ApiErrorResponse>).response?.data?.message ??
              'An error occurred while checking the FRAs',
          );
        else
          setCheckResponseMessage('An error occurred while checking the FRAs');
        setCheckResponse(false);
      }
      setIsLoading(false);
    } else {
      showError(
        'Required fields missing',
        'Please make sure to fill all fields',
      );
    }
  };

  return (
    <>
      <Flex
        direction="column"
        gap={20}
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[6]
              : theme.colors.gray[0],
          color:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[0]
              : theme.colors.gray[9],
          padding: theme.spacing.xl,
          borderRadius: theme.radius.md,
        })}
        className="px-10 md:px-32"
        align="center"
      >
        <Title style={{ textAlign: 'center', margin: '10px' }}>
          FRA Checker
        </Title>
        <Stack spacing="lg" maw="max-content">
          <Input.Wrapper label="User VID" withAsterisk>
            <Input
              icon={<IconUser />}
              value={vid}
              onChange={(val) => setVid(val.target.value as unknown as number)}
              placeholder="VID"
              type="number"
            />
          </Input.Wrapper>
          <Autocomplete
            withAsterisk
            label="ATC Position"
            icon={<IconMapPin />}
            placeholder="Position"
            onChange={(val) => {
              setPosition(val.toUpperCase());
            }}
            defaultValue={position}
            color="ivao-blue"
            data={autoCompleteData}
            transition="pop-top-left"
            transitionDuration={20}
            transitionTimingFunction="ease"
          />
          <DatePicker
            icon={<IconCalendar />}
            withAsterisk
            defaultValue={new Date()}
            label="Date of connection"
            clearable={false}
            onChange={(d: Date) => {
              const temp: Date = date;
              temp.setDate(d.getDate());
              temp.setMonth(d.getMonth());
              temp.setFullYear(d.getFullYear());
              setDate(temp);
            }}
          />
          <TimeInput
            description="Use UTC (Zulu) time"
            icon={<IconClock />}
            value={time}
            onChange={(tm) => {
              setTime(tm);
            }}
            color="ivao-blue"
            withAsterisk
            label="Time of connection"
          />
        </Stack>
        <Button
          onClick={() => void submitData()}
          color="green"
          type="submit"
          size="lg"
          style={{ backgroundColor: theme.colors.green[6] }}
          loading={isLoading}
        >
          Check
        </Button>
        {checkResponse !== undefined ? (
          <div
            className="text-center"
            style={{
              color: checkResponse
                ? theme.colors.green[6]
                : theme.colors.red[6],
            }}
          >
            <b>
              {checkResponse
                ? `This user can connect to that position`
                : 'This user cannot connect that position'}
            </b>
            <p>{checkResponseMessage}</p>
          </div>
        ) : null}
      </Flex>
    </>
  );
};
